import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      {/* <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Business Team</h2>
          <p>
            Our business team is composed of highly skilled and experienced
            professionals dedicated to providing exceptional service. We take
            pride in our work and strive to exceed customer expectations in
            every project we undertake.
          </p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div> */}
    </div>
  );
};
